var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (t, i) {
  exports = i();
}(exports, function () {
  "use strict";

  var t = "minute",
      i = /[+-]\d\d(?::?\d\d)?/g,
      e = /([+-]|\d\d)/g;
  return function (s, f, n) {
    var u = f.prototype;
    n.utc = function (t) {
      var i = {
        date: t,
        utc: !0,
        args: arguments
      };
      return new f(i);
    }, u.utc = function (i) {
      var e = n(this.toDate(), {
        locale: (this || _global).$L,
        utc: !0
      });
      return i ? e.add(this.utcOffset(), t) : e;
    }, u.local = function () {
      return n(this.toDate(), {
        locale: (this || _global).$L,
        utc: !1
      });
    };
    var o = u.parse;

    u.parse = function (t) {
      t.utc && ((this || _global).$u = !0), this.$utils().u(t.$offset) || ((this || _global).$offset = t.$offset), o.call(this || _global, t);
    };

    var r = u.init;

    u.init = function () {
      if ((this || _global).$u) {
        var t = (this || _global).$d;
        (this || _global).$y = t.getUTCFullYear(), (this || _global).$M = t.getUTCMonth(), (this || _global).$D = t.getUTCDate(), (this || _global).$W = t.getUTCDay(), (this || _global).$H = t.getUTCHours(), (this || _global).$m = t.getUTCMinutes(), (this || _global).$s = t.getUTCSeconds(), (this || _global).$ms = t.getUTCMilliseconds();
      } else r.call(this || _global);
    };

    var a = u.utcOffset;

    u.utcOffset = function (s, f) {
      var n = this.$utils().u;
      if (n(s)) return (this || _global).$u ? 0 : n((this || _global).$offset) ? a.call(this || _global) : (this || _global).$offset;
      if ("string" == typeof s && (s = function (t) {
        void 0 === t && (t = "");
        var s = t.match(i);
        if (!s) return null;
        var f = ("" + s[0]).match(e) || ["-", 0, 0],
            n = f[0],
            u = 60 * +f[1] + +f[2];
        return 0 === u ? 0 : "+" === n ? u : -u;
      }(s), null === s)) return this || _global;
      var u = Math.abs(s) <= 16 ? 60 * s : s,
          o = this || _global;
      if (f) return o.$offset = u, o.$u = 0 === s, o;

      if (0 !== s) {
        var r = (this || _global).$u ? this.toDate().getTimezoneOffset() : -1 * this.utcOffset();
        (o = this.local().add(u + r, t)).$offset = u, o.$x.$localOffset = r;
      } else o = this.utc();

      return o;
    };

    var h = u.format;
    u.format = function (t) {
      var i = t || ((this || _global).$u ? "YYYY-MM-DDTHH:mm:ss[Z]" : "");
      return h.call(this || _global, i);
    }, u.valueOf = function () {
      var t = this.$utils().u((this || _global).$offset) ? 0 : (this || _global).$offset + ((this || _global).$x.$localOffset || new Date().getTimezoneOffset());
      return (this || _global).$d.valueOf() - 60000 * t;
    }, u.isUTC = function () {
      return !!(this || _global).$u;
    }, u.toISOString = function () {
      return this.toDate().toISOString();
    }, u.toString = function () {
      return this.toDate().toUTCString();
    };
    var l = u.toDate;

    u.toDate = function (t) {
      return "s" === t && (this || _global).$offset ? n(this.format("YYYY-MM-DD HH:mm:ss:SSS")).toDate() : l.call(this || _global);
    };

    var c = u.diff;

    u.diff = function (t, i, e) {
      if (t && (this || _global).$u === t.$u) return c.call(this || _global, t, i, e);
      var s = this.local(),
          f = n(t).local();
      return c.call(s, f, i, e);
    };
  };
});
export default exports;